/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

#modal-waiting {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 999999;
    visibility: visible;
    opacity: 0.6;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;

    &.hide {
        visibility: hidden;
        opacity: 0;
    }

    h4 {
        margin-top: 10px;
        letter-spacing: 0.02em;
        opacity: 0;
        text-transform: uppercase;
        -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
        -o-animation: loading-text-opacity 2s linear 0s infinite normal;
        -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
        animation: loading-text-opacity 2s linear 0s infinite normal;
    }

    img.animated-logo {
        -moz-animation: rotation 2s infinite linear;
        -o-animation: rotation 2s infinite linear;
        -webkit-animation: rotation 2s infinite linear;
        animation: rotation 2s infinite linear;
    }
}


.title {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #405065;
    font-size: 36px;
    font-weight: bold;
    line-height: 49px;
}


.customBoxImage {
    margin-top: 5%;
    padding: 8px 100px;
    // box-shadow: 0 10px 40px 0 #B0C1D9;
    width: 60px;
    height: 35px;
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}

.connectingConnectorLabel {
    height: 23px;
    width: 110px;
    border-radius: 13px;
    color: #FFFFFF;
    text-align: center;
    z-index: 3 !important;
    left: 50% !important;
    background-color: #456;
}


.bag {
    min-height: 50px;
}


.dna-input-has-error {
    color: red !important;
    border-bottom: 1px solid red !important;
}

table {
    width: 100%;
}

tr.example-detail-row {
    height: 0;
}

tr.example-element-row:not(.example-expanded-row):hover {
    background: #777;
}

tr.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-bottom-width: 0;
}

.example-element-detail {
    overflow: hidden;
    display: flex;
}

.example-element-diagram {
    min-width: 80px;
    border: 2px solid black;
    padding: 8px;
    font-weight: lighter;
    margin: 8px 0;
    height: 104px;
}

.example-element-symbol {
    font-weight: bold;
    font-size: 40px;
    line-height: normal;
}

.example-element-description {
    padding: 16px;
}

.example-element-description-attribution {
    opacity: 0.5;
}

.example-container {
    position: relative;
    min-height: 200px;
}

.table-container {
    position: relative;
    overflow: auto;
}

table {
    width: 100%;
}

.loading-shade {
    min-height: 125px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(179, 178, 170, 0.329);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-shade-button {
    width: 80%;
    height: 80%;
    position: relative;
    background: rgba(179, 178, 170, 0.329);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: rgba(179, 178, 170, 0);
}

.app.indigo-light .mat-progress-spinner circle,
.app.indigo-light .mat-spinner circle {
    stroke: rgba(179, 178, 170, 0) !important;
}

.avatar {
    // background-image: url('assets/img/logo_pequeno.svg');
    animation: none;

    background-size: 60px;
    width: 60px;
    height: 60px
}

.avatar-download {
    // background-image: url('assets/img/logo_pequeno.svg');
    animation: none;
    background-repeat: no-repeat;
    background-size: 47px;
    width: 40px;
    height: 40px
}

.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 0.55rem !important;
    padding-top: 0.01rem !important;
    padding-bottom: 0.01rem !important;
}

.columns {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.55rem;
    padding-top: 0.05rem !important;
    padding-bottom: 0.05rem !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1em !important;
}

.orange {
    background-color: #fcba3fe5 !important;
}

.green {
    background-color: #59c737e5 !important;
}

.red {
    background-color: #fc453fe5 !important;
}

mat-row:last-child {
    border-bottom: none !important;
}

mat-row:hover {
    background-color: whitesmoke !important;
}

.custom-overlay {
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
}

ul {
  list-style: none;
}

.app.indigo-light .bg-primary {
  background: #303f9f;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400;
}
